.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header-logo {
  height: 1.5em; /* Adjusts the height to be similar to the surrounding text */
  width: auto; /* Keeps the image aspect ratio intact */
  vertical-align: middle; /* Aligns the logo vertically with any adjacent text */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.app {
  font-family: 'Arial', sans-serif;
  color: #333;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}
  
.header-content {
  display: flex;
  justify-content: space-between; /* This will space out the logo and the buttons */
  align-items: center; /* Align items vertically */
  width: 100%; /* Ensure the container spans the full width */
}

/* Optional: If you want the buttons to be grouped together on the right */
.header-buttons {
  display: flex;
  gap: 10px; /* Adjust the space between buttons */
}


.header-logo {
  font-weight: bold;
  font-size: 1.2em;
}

.contact-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.main-content {
  flex-grow: 1;
  background-color: #f8f8f8;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.investments-title {
  margin-bottom: 30px;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px; /* Set fixed width or use max-width for responsiveness */
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.card img {
  width: 100%; /* Make the image fill the container width */
  height: 200px; /* Fixed height for all images */
  object-fit: cover; /* This will cover the area of the box without breaking the aspect ratio */
  border-top-left-radius: 8px; /* Optional: Match the card's border radius */
  border-top-right-radius: 8px; /* Optional: Match the card's border radius */
  margin-bottom: 20px; /* Adds space between the image and the content below it */
}


.card:hover {
  transform: translateY(-10px);
}

.card-content h2 {
  font-size: 1.1em;
  margin-bottom: 15px;
}

.app-footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

.footer-content {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  background-color: #333; /* Your main background color */
  padding: 20px; /* Add padding as needed */
  margin-top: auto; /* Push the footer to the bottom of the layout */
}

.card-content {
  text-align: center; /* Center the text inside the card */
}

/* Add a class for the images inside the cards */
.card-image {
  width: 100%; /* Make the image take up the full width of the card */
  height: auto; /* Keep the image's aspect ratio */
  border-top-left-radius: 8px; /* Match the card's border radius */
  border-top-right-radius: 8px; /* Match the card's border radius */
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  /* Other styling as needed */
}


@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center; /* This centers the cards vertically if they're narrower than the container */
  }
}
