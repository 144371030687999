.app-header {
  width: 100%; /* Full width */
  padding: 10px 0; /* Adjust padding as needed */
}

.header-content {
  max-width: 1200px; /* Adjust based on your design */
  margin: 0 auto; /* Center align content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Padding on the sides */
}

.header-logo {
  height: 50px; /* Adjust size as needed */
}

.job-background {
  background-color: #f5f5f5; /* Light grey background */
  padding-bottom: 50px;
  margin-top: 20px; /* Margin between job post and navbar */
}


.job-card h3 {
  margin-top: 20px; /* Adjust the top margin as needed */
  margin-bottom: 20px; /* Adjust the bottom margin as needed */
}


.job-card {
  background-color: #fff; /* White background for the card */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px; /* Existing padding */
  max-width: 800px;
  margin: auto;
  margin-top: 30px; /* Top margin */
  margin-bottom: 30px; /* Bottom margin */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .job-card {
    margin-left: 20px; /* Increase left padding for mobile screens */
    margin-right: 20px; /* Increase right padding for mobile screens */
  }
}



.job-card h1, .job-card h2 {
  color: #333;
}

.job-card ul {
  list-style-type: square;
  padding-left: 20px; /* Add padding to align list inside card */
}

.job-card li {
  margin-bottom: 10px; /* Add space between list items */
}
